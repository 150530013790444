<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/home">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.depository") }}</li>
        <li class="right">
          <button type="button" @click="showHelp">
            <i class="iconfont icon-bangzhu"></i>
          </button>
        </li>
      </ul>
    </div>
    <div class="main main_page" v-if="userDetail && Funds_accepted_in_trust">
      <div class="robai_one">
        <div class="one_box">
          <ul>
            <h3>
              {{ t("profile.label.walletBalance") }}
              <span>{{ formatMoney(userDetail.amount) }}</span>
            </h3>
            <h3>
              {{ t("profile.label.Funds_accepted_in_trust") }}
              <span>{{
                formatMoney(Funds_accepted_in_trust.amountTotal)
              }}</span>
            </h3>
            <li>
              <p>
                {{ t("home.experienceGold") }}
                <span>{{ formatMoney(userDetail.experienceGold) }}</span>
              </p>
              <p>
                {{ t("profile.label.yesterdayEarn") }}
                <span>
                  {{ formatMoney(Funds_accepted_in_trust.yesterdayIncome) }}
                </span>
              </p>
              <p>
                {{ t("profile.label.cumulativeIncome") }}
                <span>{{
                  formatMoney(Funds_accepted_in_trust.totalIncome)
                }}</span>
              </p>
            </li>
          </ul>
          <div class="liks_a">
            <router-link to="/order_ai">
              {{ t("profile.menu.orderRecord") }}
            </router-link>
            |
            <router-link to="/recharge">
              {{ t("profile.menu.recharge") }}
            </router-link>
            |
            <router-link to="/withdraw">
              {{ t("profile.menu.withdraw") }}
            </router-link>
          </div>
        </div>
      </div>

      <div class="robai_two">
        <div class="two_box">
          <label
            :for="`checkbox-1-${index + 1}`"
            :key="item.id"
            v-for="(item, index) in listItems"
          >
            <!-- <input
              type="radio"
              name="checkbox-1"
              :id="`checkbox-1-${index + 1}`"
              :value="index"
              v-model="catIndex"
              :disabled="+userDetail.levelName?.slice(3) < +item.level"
            /> -->
            <input
              type="radio"
              name="checkbox-1"
              :id="`checkbox-1-${index + 1}`"
              :value="index"
              v-model="catIndex"
              
              :disabled="parseFloat(userDetail.amount) < parseFloat(item?.minAmount)"
            />
            <ul>
              <h2>
                <span>
                  <b>{{ item.days }}</b>
                  {{ t("profile.label.days") }}
                </span>
              </h2>
              <li>
                {{ t("profile.label.expectedProfits") }}
                <span>
                  {{ formatPercentage(item.minEarnings) }}-{{
                    formatPercentage(item.maxEarnings)
                  }}
                </span>
              </li>
              <li>
                {{ t("profile.label.fee") }}
                <span>{{ formatPercentage(item.fee) }}</span>
                {{ t("profile.label.min") }}
                <span>{{ formatMoney(item.minAmount, false) }}</span>
                {{ t("profile.label.max") }}
                <span>{{ formatMoney(item.maxAmount, false) }}</span>
              </li>
              <li>
                {{ t("profile.label.vipLevelRequired") }}
                <VipLevel :level="item.level"></VipLevel>
              </li>
            </ul>
          </label>
        </div>
      </div>

      <div class="Sys_submit_btn robai_Sys_submit_btn">
        <button type="button" @click="show">
          {{ t("common.button.transfer") }}
        </button>
      </div>
      <div class="robai_three text-pre-line">
        {{ t("common.text.depository") }}
      </div>
    </div>
  </div>
  <Loading :loading="loading" />
  <AModal
    v-model:visible="helpVisible"
    centered
    :footer="null"
    :title="t('extra.instructions')"
    wrapClassName="Sys_Popup"
    width="85%"
  >
    <div class="Sys_Popup_wrap">
      <div class="box text-pre-line">
        {{ t("common.text.ai_trust_management_help") }}
      </div>
    </div>
  </AModal>
  <AModal
    v-if="currentItem"
    v-model:visible="visible"
    :footer="null"
    :title="t('common.button.transfer')"
    @ok="handleOk"
    wrapClassName="Popup_bottom"
    width="100%"
  >
    <div class="Popup_bottom_box robai">
      <ul>
        <h3>{{ t("profile.placeholder.transAmount") }}</h3>
        <li>
          <b>{{ CURRENCY_SYMBOL }}</b>
          <AForm
            ref="formRef"
            :model="formModel"
            :validateTrigger="['blur', 'change']"
            class="robai"
            autocomplete="off"
          >
            <AFormItem name="amount" v-bind="validateInfos.amount">
              <input class="t_btn"
                type="number"
                v-model.number="formModel.amount"
                :placeholder="
                  t('profile.hits.minimumAmount', {
                    amount: formatMoney(expect.minAmount),
                  })
                "
              />
            </AFormItem>
          </AForm>
        </li>
        <li>
          {{ t("profile.label.expectedReturn") }}
          <b>{{ expect.return }}</b>
          {{ t("profile.label.fee") }}
          <b>{{ expect.depositoryFeeDiscount }}</b>
          <span>
            {{ t("profile.label.expireDate") }}
            <b>{{ expect.expireDate }}</b>
          </span>
        </li>
      </ul>
      <ol>
        <h3>
          <b>{{ currentItem.days }}</b>
          <span>{{ t("profile.label.days") }}</span>
          {{ t("profile.label.expectedProfits") }}:
          <span>
            {{ formatPercentage(currentItem.minEarnings) }}-{{
              formatPercentage(currentItem.maxEarnings)
            }}
          </span>
          {{ t("profile.label.fee") }}:
          <span>{{ formatPercentage(currentItem.fee) }}</span>
        </h3>
        <li>
          {{ t("profile.label.min") }}:
          <span>{{ formatMoney(currentItem.minAmount, false) }}</span>
          {{ t("profile.label.max") }}:
          <span>{{ formatMoney(currentItem.maxAmount, false) }}</span>
        </li>
      </ol>
    </div>
    <div class="Sys_submit_btn robai">
      <button
        type="button"
        @click="handleOk"
        :disabled="!(validateInfos.amount?.validateStatus == 'success')"
      >
        {{ t("common.button.confirm") }}
      </button>
    </div>
  </AModal>
</template>
<script>
import { ref, onMounted, unref, reactive, watch } from "vue";
import { formatMoney, formatPercentage } from "@/utils/formatter";
import VipLevel from "@/components/VipLevel.vue";
import { useI18n } from "@/lang";
import { userDetail } from "@/hooks/userDetail";
import api from "@/api";
import { Form, message } from "ant-design-vue";
import dayjs from "dayjs";
import Loading from "@/components/Loading.vue";
import { CURRENCY_SYMBOL } from "@/utils/constant";
export default {
  components: {
    VipLevel,
    Loading,
  },
  setup() {
    onMounted(() => {
      api.incomeBreakdown().then((res) => {
        if (res.data?.success) {
          Funds_accepted_in_trust.value = res.data.data;
        }
      });
      api.orderAiList().then((res) => {
        if (res.data?.success) {
          listItems.value = res.data.data;
          console.log(listItems.value, "listItems");
        }
      });
    });
    const { t } = useI18n();
    const loading = ref(false);
    const visible = ref(false);
    const listItems = ref([]);
    const currentItem = ref(null);
    const Funds_accepted_in_trust = ref(null);
    const catIndex = ref(-1);
    const useForm = Form.useForm;
    const formModel = reactive({ amount: null });
    const expect = reactive({
      expireDate: "",
      return: 0,
      depositoryFeeDiscount: 0,
      minAmount: 0,
      maxAmount: 0,
    });
    const ruleRef = reactive({
      amount: [
        { required: true, message: t("profile.placeholder.transAmount") },
        {
          validator: (_rule, val) => {
            if (val < expect.minAmount) {
              return Promise.reject(
                t("profile.hits.minimumAmount", {
                  amount: formatMoney(expect.minAmount, false),
                })
              );
            } else {
              return Promise.resolve();
            }
          },
        },
        {
          validator: (_rule, val) => {
            if (val > expect.maxAmount) {
              return Promise.reject(
                t("profile.hits.maximumAmount", {
                  amount: formatMoney(expect.maxAmount, false),
                })
              );
            } else {
              return Promise.resolve();
            }
          },
        },
        {
          validator: (_rule, val) => {
            if (
              val > (userDetail.value && userDetail.value.amount) ||
              !(userDetail.value && userDetail.value.amount)
            ) {
              return Promise.reject(t("error.grab.insufficient"));
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
    });
    watch([currentItem, formModel, userDetail], () => {
      console.log("watch item & form..", formModel.amount);
      const item = unref(currentItem);
      if (!item) return;
      let dd = dayjs(new Date());
      dd.add(item.days, "day");
      expect.expireDate = dd.format("YYYY-MM-DD");

      expect.minAmount = item.minAmount;
      expect.maxAmount = item.maxAmount;

      const minRet = formModel.amount * (1 + item.minEarnings * item.days);
      const maxRet = formModel.amount * (1 + item.maxEarnings * item.days);
      expect.return = `${formatMoney(minRet, false)}~${formatMoney(
        maxRet,
        false
      )}`;

      expect.depositoryFeeDiscount = formatPercentage(item.fee);
    });
    const { resetFields, validate, validateInfos } = useForm(
      formModel,
      ruleRef
    );
    const show = () => {
      currentItem.value = listItems.value[catIndex.value];
      visible.value = true;
    };
    const handleOk = () => {
      validate().then((v) => {
        console.log(v, ",", resetFields, ",", validateInfos);
        console.log(currentItem, "current");
        if (formModel.amount > userDetail.value.amount) {
          message.error(t("error.grab.insufficient"));
          return;
        }
        loading.value = true;
        api
          .userBuyRecorSave({
            amount: formModel.amount,
            orderConfigId: currentItem.value?.id,
          })
          .then((res) => {
            loading.value = false;
            if (res?.data?.success) {
              api.getUserDetails();
              api.incomeBreakdown().then((res) => {
                if (res.data?.success) {
                  Funds_accepted_in_trust.value = res.data.data;
                }
              });
              visible.value = false;
            }
          })
          .catch(() => {
            loading.value = false;
          });
      });
    };
    let helpVisible = ref(false);
    const showHelp = () => {
      helpVisible.value = true;
    };
    return {
      t,
      helpVisible,
      formatMoney,
      showHelp,
      userDetail,
      expect,
      listItems,
      formatPercentage,
      handleOk,
      show,
      formModel,
      visible,
      catIndex,
      currentItem,
      validateInfos,
      CURRENCY_SYMBOL,
      loading,
      Funds_accepted_in_trust,
    };
  },
  // methods: {
  //   goTo() {
  //     this.$router.push('/Home');
  //   },
  // },
};
</script>
<style lang="scss">
.t_btn{
  padding-left: 30px !important;
}
</style>


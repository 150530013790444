<template>
  <div class="div01">
    <StaticImage :url="`level_0${level}.png`" class="img01"/>
    <!-- <span class="span01">{{ t("home.integral") }}:{{ userDetail.integralTotal }}</span> -->
  </div>
</template>
<script>
import { useI18n } from "@/lang";
import { userDetail } from "@/hooks/userDetail";
import StaticImage from '@/components/StaticImage.vue';
export default {
  props: ['level'],
  components: {
    StaticImage,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
      userDetail
    };
  }
};
</script>
<style lang="scss" scoped>

.div01{
  display: inline-block;
  width:100%;
}
.span01{
  height: 0;
  position: relative;
  top:-18px;
  left:60px;
}
</style>
